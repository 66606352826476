import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';

import BlogContainer from '../../components/Blog/BlogContainer';
import BlogCoverImage from '../../components/Blog/BlogCoverImage';
import BlogShare from '../../components/Blog/BlogShare';

export default function QrKodoviZaWifi({location}) {
	const data = useStaticQuery(graphql`
		query {
			coverImage: file(relativePath: {eq: "blog/wifi-coffee.jpg"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fluid(maxWidth: 1600) {
						...GatsbyImageSharpFluid
					}
				}
			}
			ogImage: file(relativePath: {eq: "blog/wifi-coffee.jpg"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fixed(width: 1080) {
						...GatsbyImageSharpFixed
					}
				}
			}
			authorImage: file(relativePath: {eq: "author/vlatko.jpg"}) {
				childImageSharp {
					fixed(width: 40) {
						...GatsbyImageSharpFixed
					}
				}
			}
			qrWifi: file(relativePath: {eq: "blog/qr-wifi.png"}) {
				childImageSharp {
					fixed(width: 280) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);

	return (
		<BlogContainer
			id={2}
			ogImage={data.ogImage.childImageSharp.fixed}
			uri={location.pathname}
			authorImage={data.authorImage.childImageSharp.fixed}
		>
			<blockquote>Omogućite svojim gostima (u poslovnom prostoru ili doma) da se jednostavno spoje na vašu WiFi mrežu.</blockquote>
			<BlogCoverImage image={data.coverImage.childImageSharp.fluid} />
			<section>
				<BlogShare />
				<p>
					<strong>WiFi</strong> mreža u vašem poslovnom prostoru više nije dodatak na uslugu, već je nešto što se podrazumjeva. Uvođenjem
					fiskalizacije, da bi izradili račun, svakako morate imati vezu na internet, tako da uz mala ulaganja (za dodatni router ili neki
					drugi mrežni uređaj) možete podijeliti svoju konekciju na internet s vašim gostima. Nećemo previše ulaziti u tehničke detalje
					vezane za sigurnost i bandwidth dijeljenja internet konekcije jer taj posao trebate povjeriti stručnjaku.
				</p>
				<p>Ugostitelji često koriste razne izlike zašto ne žele uvesti ili podijeliti internet sa svojim gostima, kao što su:</p>
				<ul>
					<li>gosti već imaju svoj internet na svom uređaju</li>
					<li>moja veza na internet je spora i strah me da fiskalizacija neće raditi</li>
					<li>bojim se da će mi netko "hakirati" kasu</li>
					<li>neka gosti piju kavu bez distrakcije</li>
				</ul>
				<h3>Gosti već imaju svoj internet na svom uređaju</h3>
				<p>
					Nažalost, ova izlika nije točna. Da, ako svoj ugostiteljski objekt imate u Hrvatskoj i dođe vam gost koji je također iz Hrvatske,
					da, taj gost će najvjerojatnije imati svoj internet i neće trebati vaš WiFi, ali ni to nije pravilo u svim slučajevima.
				</p>
				<p>Razmišljajte šire i sjetite se da postoje:</p>
				<ul>
					<li>
						gosti koji su <strong>iz EU</strong> - iako su oni u roamingu i mogu koristiti svoj internet bez dodatnih naknada, možda nemaju
						podešene roaming postavke pa im internet radi samo u njihovoj matičnoj zemlji - oni žele vaš WiFi
					</li>
					<li>
						gosti koji su iz zemalja <strong>van EU</strong> - oni moraju uložiti dodatan napor da imaju nekakav internet sa sobom, a vaš
						WiFi im je najlakše rješenje
					</li>
					<li>
						<strong>domaći gosti</strong> koji su možda već potrošili svoj internet i jednostavnije im je spojiti se na WiFi nego napraviti
						nadoplatu u tom trenutku
					</li>
					<li>
						možda vaši gosti nemaju dobar signal za svoj internet, pa im je lakše spojiti se na WiFi (razlozi za loš signal mogu biti
						različiti; od slabe pokrivenosti signalom (nemaju svi mobilni operateri istu pokrivenost), &quot;blind spots&quot; (refleksija
						signala) ili jednostavno loš prijem signala određenih mobilnih uređaja)
					</li>
				</ul>
				<h3>Moja veza na internet je spora i strah me da fiskalizacija neće raditi</h3>
				<p>
					Ova situacija se može pokazati točnom u vašem ugostiteljskom objektu, ali to je onda znak da dijeljenje vaše internet veze nije
					dobro napravljeno. Postoje razna rješenja kako ovo popraviti, a jedno od rješenja je da se postave prioriteti na mreži koji
					uređaji imaju najveći prioritet sa zagarantiranim bandwidthom. Na taj način, vaši gosti nikada neće moći zauzeti kompletni
					bandwidth vaše konekcije čime će vaša kasa uvijek imati internet potreban za fiskalizaciju. Postavke za priotitete se nalaze na
					većini routera, najčešće pod opcijom <strong>QoS</strong> - Quality of Service.
				</p>
				<p>Da - ovo će onemogućiti da vaši gosti imaju brzu vezu.</p>
				<p>
					Da - ako je vaša veza na internet spora, vašim gostima će biti još sporije. Ali hej, bitnije je da vaši gosti mogu poslati poruku
					putem aplikacija za dopisivanje (koje koriste izuzetno malo bandwidtha) nego da mogu pogledati nebitne video isječke na društvenim
					mrežama.
				</p>
				<p>
					Da - ovo može raditi čak i ako imate <strong>2mbit</strong> download konekciju.
				</p>
				<p>
					<strong>Posao podešavanja vašeg routera svakako povjerite stručnjaku.</strong>
				</p>
				<h3>Bojim se da će mi netko "hakirati" kasu</h3>
				<p>
					Ovaj razlog može biti točan, ali je isto znak da vaša mreža nije dobro konfigurirana. Nažalost, većina ugostitelja ostavi sve
					postavke svog routera na tvorničkim postavkama, pa svatko s osnovnim znanjem i malo volje može napraviti loše stvari na vašoj
					mreži, potpuno ju onesposobiti ili još gore - presresti promet drugim korisnicima koji su spojeni na vaš router. U slučaju
					potpunog onesposobljavanja, prestat će vam raditi fiskalizacija i u prvom trenutku nećete znati koji je razlog tome.
				</p>
				<p>
					Konfigurirajte svoju mrežu prema sigurnosnim standardima, izolirajte podmrežu na koju se spajaju vaši gosti kako ne bi imali
					pristup vašoj glavnoj mreži, postavite jaču lozinku za vaš WiFi i mijenjajte ju barem svakih par mjeseci. Lozinke tipa{' '}
					<strong>pitajkonobara</strong>, <strong>12345678</strong> i slične više nisu fora. Obavezno{' '}
					<strong>angažirajte stručnjaka</strong> koji će vam srediti sigurnost vaše mreže. Lakše je i jeftinije je napraviti prevenciju,
					nego kasnije rješavati posljedice.
				</p>
				<h3>Neka gosti piju kavu bez distrakcije</h3>
				<p>
					Lijepo od Vas da tako razmišljate jer, iskreno, lijepo je popiti kavu i/ili pojesti nešto bez gledanja u mobilni uređaj. Ali
					nažalost, ova značajka je individualna za svaku osobu, tako da netko tko želi popiti kavu bez distrakcije će to moći i u objektu
					gdje WiFi postoji.
				</p>
				<p>
					Realnost je da većina ljudi želi u svakom trenutku imati mogućnost komunikacije i prije će se dogoditi da će vas gost zaobići jer
					će otići u objekt gdje WiFi postoji nego negdje gdje ne postoji.
				</p>
				<h2>Istaknite QR za vaš WiFi</h2>
				<p>
					Već smo istaknuli da bi lozinka za vašu WiFi mrežu trebala biti jaka lozinka. Koristite velika i mala slova, brojke i znakove i
					neka lozinka ima barem 10 znakova, a poželjno je i više. Kada ovo kažemo, često dobijemo nazad odgovor:{' '}
					<em>pa tko bi prepisao takvu lozinku</em>!
				</p>
				<p>
					Postavljanjem <strong>QR kȏd-a za WiFi</strong> rješava se problem kompliciranih lozinki jer ne zahtjeva da vaš gost prepisuje
					komplicirane lozinke što znatno ubrzava cijelu stvar i sprječava da vaš gost pogrešno prepiše lozinku, a time se smanjuje i
					potencijalna frustracija vašeg gosta.
				</p>
				<p>
					Postavljanjem <strong>QR kȏd-a za WiFi</strong> u svom ugostiteljskom objektu uklanja potrebu da vaša WiFi lozinka bude vidljiva{' '}
					<em>golim okom</em>.
				</p>
				<p>
					Pogledajte primjer QR kȏda u koji su ukodirane informacije za spajanje na WiFi mrežu pod nazivom <strong>qr-cjenik.hr</strong>:
				</p>
				<div className="text-center">
					<Img fixed={data.qrWifi.childImageSharp.fixed} />
				</div>
				<p>
					Ovakav QR kȏd možete izraditi na mnogim stranicama za ručno kreiranje QR kodova, kao što je{' '}
					<a href="https://www.qrcode-monkey.com" target="_blank">
						QRCode Monkey
					</a>
					. Budite kreativni gdje ćete postaviti QR kȏd za spajanje na WiFi i napravite da se vaš gost kod vas osjeća kao doma!
				</p>
				<p>
					<strong>Napomena:</strong> sve navedeno u ovom članku je primjenjivo i u vašem domu. Ugostite svoje goste i kad Vas pitaju za
					lozinku za WiFi, recite im: <em>skeniraj ovaj QR kȏd i to je to!</em>
				</p>
			</section>
		</BlogContainer>
	);
}

QrKodoviZaWifi.propTypes = {
	// eslint-disable-next-line
	location: PropTypes.object
};
